.space-between {
  display: flex;
}

.space-between > * {
  margin-left: 10px;
  margin-right: 10px;
}

.space-between > *:first-child {
  margin-left: unset;
}

.space-between > *:last-child {
  margin-right: unset;
}
